import {
  cancelSeatInvitation,
  deleteSeatFromAccountApi,
  getAccountAnalyticsApi,
  getAccountApi,
  inviteUserApi,
  purchaseSeatsApi,
  putAccountApi,
  removeSeatFromTeamMemberApi,
  updateTemplateSortOrder,
  updateToNonTexterApi,
  updateToTexterApi,
} from '@/api/accountApi';
import {
  loginApi,
  logoutApi,
  resetPasswordUsingCodeApi,
  resetPasswordWithEmailApi,
  signupApi,
  verifyMfaApi,
  sendMfaEmailApi,
  getSsoUrlApi,
  sendVerificationEmailApi,
} from '@/api/authApi';
import {changeUserType, deleteUserApi, getUserAnalyticsApi, getUserApi, updateCallForwardSettingsApi, updateUserApi} from '@/api/userApi';
import router from '../routes/router';
import {authPages} from '@/routes/routes';
import {deleteCookie, setCookie} from '@/util/appLocalStorage';
import appNotification from '../util/appNotification';
import {addSecondsToCurrent} from '@/util/appTime';
import {
  ACCOUNT_ANALYTICS_SUCCESS,
  BUY_PHONE_NUMBER_REQUEST,
  BUY_PHONE_NUMBER_SUCCESS,
  CHANGE_USER_TYPE_REQUEST,
  CLEAR_ATS_INTEGRATIONS,
  CLEAR_CALENDARS_DATA,
  CLEAR_INTERVIEW_REMINDERS_DATA,
  CLEAR_ONBOARDINGS_DATA,
  CLEAR_PAYMENTS_DATA,
  CLEAR_PLANS_DATA,
  CLEAR_SEATS_DATA,
  CLEAR_TEMPLATES_DATA,
  CLEAR_USER_OFFICE_MSG,
  DELETE_SEAT_FROM_ACCOUNT_REQUEST,
  DELETE_USER_REQUEST,
  ENABLE_LANDLINE_REQUEST,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_AUTH_DATA_REQUEST,
  FETCH_SEATS_REQUEST,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  GET_ACCOUNT_ANALYTICS,
  GET_USER_ANALYTICS,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  LOADING_STATE_SET,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  PURCHASE_SEATS_REQUEST,
  REINVITE_USER_ACTIVE,
  REMOVE_SEAT_REQUEST,
  RESET_NUMBER_REQUEST,
  RESET_PASSWORD_SEND_EMAIL_REQUEST,
  RESET_PASSWORD_SEND_EMAIL_SUCCESS,
  RESET_PASSWORD_USING_CODE_REQUEST,
  RESET_PASSWORD_USING_CODE_SUCCESS,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_CALL_FORWARDING_REQUEST,
  UPDATE_TEMPLATE_ORDER_REQUEST,
  UPDATE_USER_REQUEST,
  UPDATE_USER_TO_TEXTER,
  USER_ANALYTICS_SUCCESS,
  SET_MFA_NEEDED,
  VERIFY_MFA,
  SEND_MFA_EMAIL,
  SEND_VERIFICATION_EMAIL,
  FETCH_SSO_URL_REQUEST,
} from './storeActions';
import _ from 'lodash';
import {buyPhoneNumberApi, enableLandlineApi, releasePhoneNumber} from '@/api/seatApi';
import {accountTypes, plansTypes, seatStatus, seatTypes, userTypes} from './userConstants';

const getInitialState = () => {
  return {
    user: {},
    account: {},
    seat: {},
    textAnalytics: {totalSentMessages: 0, totalReceivedMessages: 0},
    accountTextAnalytics: [],
    isBuyPhoneNumber: false,
    mfaNeeded: false, 
  };
};

const state = getInitialState();

const getters = {
  userId: (state) => state.user.id,
  userType: (state) => state.user.userType,
  userFirstName: (state) => state.user.firstName,
  userLastName: (state) => state.user.lastName,
  userEmail: (state) => state.user.email,
  userEmailVerified: (state) => _.get(state.user, 'metadata.emailVerified', false),
  userPhoneNumber: (state) => state.user.phoneNumber,
  userTimezone: (state) => state.user.timezone,
  userCompany: (state) => state.account.name,
  userAvatar: (state) => state.user.avatar || 'img/tools/default_user.jpg',
  usedSeats: (state) => !_.isEmpty(state.account.seats) && state.account.seats.used,
  availableSeats: (state) => !_.isEmpty(state.account.seats) && state.account.seats.available,
  totalSeats: (state) => !_.isEmpty(state.account.seats) && state.account.seats.total,
  accountType: (state) => state.account.type,
  twilioNumberFormatted: (state) => state.seat.twilioNumberFormatted,
  planType: (state) => state.account.planType,
  callForwardingEnabled: (state) => state.user.callForwardingEnabled,
  callForwardingNumber: (state) => state.user.callForwardingNumber,
  textAnalytics: (state) => state.textAnalytics,
  accountTextAnalytics: (state) => state.accountTextAnalytics,
  subscriptionInfo: (state) => state.account.subscriptionInfo,
  userAutomationReminder: (state) => state.user.automationReminder,
  interviewReminderEnabled: (state) => state.user.interviewReminderEnabled,
  onboardingMessageEnabled: (state) => state.user.onboardingMessageEnabled,
  seatType: (state) => state.seat && state.seat.id ? seatTypes.TEXTER : seatTypes.NON_TEXTER,
  seatRole: (state) => state.seat.seatRole,
  outOfOfficeMessageEnabled: (state) => state.user.outOfOfficeMessageEnabled,
  outOfOfficeMessage: (state) => state.user.outOfOfficeMessage,
  accountName: (state) => state.account.name ? state.account.name : null,
  userCalendarShortcut: (state) => state.user.calendarShortcutEnabled,
  isBuyPhoneNumber: (state) => state.isBuyPhoneNumber,
  totalTemplates: (state) => state.account.metadata.totalTemplates,
  totalTeamTemplates: (state) => state.account.metadata.totalTeamTemplates,
  featuresEnabled: (state) => state.account.metadata.featuresEnabled,
};

const actions = {
  [LOGIN_REQUEST]: async (
    {commit, dispatch, getters}, {currentRequest, requestData}) => {
    const loaderText = (currentRequest === 'login') ?
      'Logging in' :
      'Registering account';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = (currentRequest === 'login') ?
        await loginApi(requestData) :
        await signupApi(requestData);
        const {id_token: idToken, expires_in: expires, mfaNeeded} = res;
        if (mfaNeeded) {
          await router.push({name: 'TwoFactorAuthentication'});
          setCookie('loggedInUserEmail', requestData.email); 
          commit(SET_MFA_NEEDED, true);
        } else {
          if (!!idToken) {
            const expiresIn = addSecondsToCurrent(expires);
            commit(LOGIN_SUCCESS, {idToken, expiresIn, mfaNeeded});
            commit(SET_MFA_NEEDED, false);
            let redirectRouteName = 'Plan Selection';
            await dispatch(FETCH_AUTH_DATA_REQUEST);
            if (currentRequest === 'login') {
              if (getters.planType !== plansTypes.FREE) {
                redirectRouteName = 'Account';
              }
            } else if (currentRequest === 'register') {
              redirectRouteName = 'Account';
            }
            await router.push({name: redirectRouteName});
          }
        }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [VERIFY_MFA]: async ({commit, dispatch, getters}, {email, code}) => {
    commit(LOADING_STATE_SET, { isLoading: true, loaderText: 'Verifying MFA' });
    try {
      const result = await verifyMfaApi({email, code});
      if (result) {
        const {id_token: idToken, expires_in: expires} = result;
        if (!!idToken) {
          const expiresIn = addSecondsToCurrent(expires);
          commit(LOGIN_SUCCESS, {idToken, expiresIn});
          appNotification.notify({message: 'MFA Verification Done!'});
          let redirectRouteName = 'Plan Selection';
          // Proceed with fetching user data and redirecting
          await dispatch(FETCH_AUTH_DATA_REQUEST);
          if (getters.planType !== plansTypes.FREE) {
            redirectRouteName = 'Account';
          }
          await router.push({name: redirectRouteName});
          return result;
        }
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [SEND_VERIFICATION_EMAIL]: async ({commit}, {email, captchaToken}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Sending Verification Email' });
    try {
      const result = await sendVerificationEmailApi({email, captchaToken});
      if (result) {
        appNotification.notify({message: 'Verification email sent!'});
      }
    } catch (e) {
      return null;
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [SEND_MFA_EMAIL]: async ({commit}, {email, captchaToken}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Sending Mail'});
    try {
      const result = await sendMfaEmailApi({email, captchaToken});
      if (result) {
        appNotification.notify({message: 'OTP sent to email!'});
      }
    } catch (e) {
      return null;
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_SSO_URL_REQUEST]: async({commit}, email) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Redirecting to SSO'});
    try {
      const result = await getSsoUrlApi(email);
      if (result.ssoUrl) {
        return result.ssoUrl;
      }
    } catch (e) {
      return null;
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [LOGOUT_REQUEST]: async ({commit}, {onServer} = {onServer: true}) => {
    try {
      if (onServer) {
        commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Logging out'});
        await logoutApi();
      }
    } catch (e) {
    } finally {
      commit(LOGOUT_SUCCESS);
      commit(CLEAR_PAYMENTS_DATA);
      commit(CLEAR_SEATS_DATA);
      commit(CLEAR_ATS_INTEGRATIONS);
      commit(CLEAR_PLANS_DATA);
      commit(CLEAR_TEMPLATES_DATA);
      commit(CLEAR_INTERVIEW_REMINDERS_DATA);
      commit(CLEAR_USER_OFFICE_MSG);
      commit(CLEAR_ONBOARDINGS_DATA);
      commit(CLEAR_CALENDARS_DATA);
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_AUTH_DATA_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Loading user info'});
    try {
      const user = await getUserApi();
      const account = await getAccountApi();
      if (user.id && account.id) {
        commit(FETCH_USER_SUCCESS, {user});
        commit(FETCH_ACCOUNT_SUCCESS, {account});
        commit(SET_MFA_NEEDED, false);
        return {user, account};
      }
    } catch (e) {
      return null;
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_USER_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading info'});
    try {
      const user = await getUserApi();
      if (user.id) {
        commit(FETCH_USER_SUCCESS, {user});
      }
      return user;
    } catch (e) {
      return null;
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_USER_REQUEST]: async (
    {commit}, userData) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Saving'});
    try {
      await updateUserApi(userData);
      const user = await getUserApi();
      appNotification.notify({message: 'Profile updated'});
      commit(FETCH_USER_SUCCESS, {user});
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_CALL_FORWARDING_REQUEST]: async ({commit}, payload) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Saving'});
    try {
      await updateCallForwardSettingsApi(payload);
      const user = await getUserApi();
      commit(FETCH_USER_SUCCESS, {user});
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_ACCOUNT_REQUEST]: async ({commit}, payload) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Saving'});
    try {
      const account = await putAccountApi(payload);
      commit(FETCH_ACCOUNT_SUCCESS, {account});
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_USER_REQUEST]: async ({commit, dispatch}) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Deleting'});
    try {
      await deleteUserApi();
      dispatch(LOGOUT_REQUEST);
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [RESET_PASSWORD_SEND_EMAIL_REQUEST]: async ({commit}, data) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Sending email'});
    try {
      const res = await resetPasswordWithEmailApi(data);
      if ((res.status === 'ok')) {
        commit(RESET_PASSWORD_SEND_EMAIL_SUCCESS);
        return true;
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [RESET_PASSWORD_USING_CODE_REQUEST]: async ({commit}, data) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Sending new password request'});
    try {
      const res = await resetPasswordUsingCodeApi(data);
      if ((res.status === 'ok')) {
        commit(RESET_PASSWORD_USING_CODE_SUCCESS);
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [INVITE_USER_REQUEST]: async ({commit, dispatch}, data) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Sending invitation'});
    try {
      const res = await inviteUserApi(data);
      if (res && res.status === 'ok') {
        dispatch(FETCH_SEATS_REQUEST);
        commit(INVITE_USER_SUCCESS);
      }
      if (res && res.status === 'okay') {
        dispatch(FETCH_SEATS_REQUEST);
        commit(REINVITE_USER_ACTIVE);
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [GET_USER_ANALYTICS]: async ({commit, dispatch}, {date}) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Fetching analytics data'});
    try {
      const textAnalytics = await getUserAnalyticsApi(date);
      commit(USER_ANALYTICS_SUCCESS, {textAnalytics});
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [GET_ACCOUNT_ANALYTICS]: async ({commit, dispatch}, {date, endDate}) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Fetching analytics data'});
    try {
      const accountTextAnalytics = await getAccountAnalyticsApi(date, endDate);
      commit(ACCOUNT_ANALYTICS_SUCCESS, {accountTextAnalytics});
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [BUY_PHONE_NUMBER_REQUEST]: async ({commit, dispatch}, {data, redirect}) => {
    commit(LOADING_STATE_SET,
      {isLoading: true, loaderText: 'Getting phone number'});
    try {
      const res = await buyPhoneNumberApi(data);
      if (!!res && res.status === 'ok') {
        dispatch(FETCH_USER_REQUEST);
        commit(BUY_PHONE_NUMBER_SUCCESS, {isBuyPhoneNumber: true});
        if (res.user.userType === userTypes.ADMIN && res.account.type === accountTypes.TEAM) {
          dispatch(FETCH_SEATS_REQUEST);
        }
        if (redirect) {
          router.push({name: redirect});
        }
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [ENABLE_LANDLINE_REQUEST]: async ({commit}, data) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Sending request'});
    try {
      await enableLandlineApi(data);
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [PURCHASE_SEATS_REQUEST]: async ({commit, dispatch}, data) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Adding seat'});
    try {
      const res = await purchaseSeatsApi(data);
      if (res.status === 'ok') {
        dispatch(FETCH_AUTH_DATA_REQUEST);
        appNotification.notify({
          header: 'Purchase successful!',
          message: 'You can start inviting new team members right away',
        });
        dispatch(FETCH_SEATS_REQUEST);
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
    return false;
  },
  [REMOVE_SEAT_REQUEST]: async ({commit, dispatch}, {payload, status}) => {
    const loaderText = (status === seatStatus.USED) ?
      'Removing' :
      'Canceling invitation';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = (status === seatStatus.USED) ?
        await removeSeatFromTeamMemberApi(payload) :
        await cancelSeatInvitation(payload);
      if (res && res.status === 'ok') {
        dispatch(FETCH_SEATS_REQUEST);
        const message = (status === seatStatus.USED || status === seatTypes.NON_TEXTER) ?
          'Seat has been successfully removed' :
          'Invite has been canceled';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_SEAT_FROM_ACCOUNT_REQUEST]: async ({commit, dispatch}, {payload}) => {
    const loaderText = 'Removing Seat';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = await deleteSeatFromAccountApi(payload);
      if (res && res.status === 'ok') {
        dispatch(FETCH_AUTH_DATA_REQUEST);
        dispatch(FETCH_SEATS_REQUEST);
        const message = 'Seat has been successfully removed from this account';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CHANGE_USER_TYPE_REQUEST]: async ({commit, dispatch}, {payload}) => {
    const loaderText = 'Changing user type';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = await changeUserType(payload);
      if (res) {
        dispatch(FETCH_SEATS_REQUEST);
        const message = 'User type updated';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [RESET_NUMBER_REQUEST]: async ({commit, dispatch}, {payload}) => {
    const loaderText = 'Resetting number';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = await releasePhoneNumber(payload);
      if (res) {
        dispatch(FETCH_SEATS_REQUEST);
        dispatch(FETCH_USER_REQUEST);
        const message = 'Number reset successfully!';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_USER_TO_TEXTER]: async ({commit, dispatch}, {payload}) => {
    const loaderText = payload.currentSeatType === seatTypes.NON_TEXTER ?
      'Updating user to texter' :
      'Updating user to non texter';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = payload.currentSeatType === seatTypes.NON_TEXTER ? await updateToTexterApi(payload) :
        await updateToNonTexterApi(payload);
      if (res) {
        dispatch(FETCH_SEATS_REQUEST);
        const message = 'User updated successfully!';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_TEMPLATE_ORDER_REQUEST]: async ({commit}, {payload}) => {
    try {
      const res = await updateTemplateSortOrder(payload);
      if (res) {
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [LOGIN_SUCCESS]: (state, {idToken, expiresIn}) => {
    // setStorageItems({idToken, expiresIn});
    setCookie('idToken', idToken, expiresIn); // For Web extension.
  },
  [LOGOUT_SUCCESS]: (state) => {
    // removeStorageItems(['idToken', 'expiresIn']);
    deleteCookie('idToken'); // For Web extension.
    deleteCookie('userId'); // For Web extension.
    deleteCookie('loggedInUserEmail'); // For Web App.
    state = Object.assign(state, getInitialState());
    if (!authPages.children.some(
      (page) => page.name === router.currentRoute.name)) {
      router.push({name: 'Login'}).catch((err) => err);
    }
  },
  [FETCH_USER_SUCCESS]: (state, {user}) => {
    setCookie('userId', user.id); // For Web extension.
    const seat = {...user.seat};
    state = Object.assign(state, {user, seat});
  },
  [RESET_PASSWORD_SEND_EMAIL_SUCCESS]: (state) => {
    appNotification.notify(
      {message: 'If the email you entered is registered in our system, we\'ve sent you a verification code. Please check your inbox and enter the code along with your new password to continue.'});
  },
  [RESET_PASSWORD_USING_CODE_SUCCESS]: (state) => {
    appNotification.notify({message: 'We have successfully updated your password. Please sign in.'});
    router.push({name: 'Login'});
  },
  [FETCH_ACCOUNT_SUCCESS]: (state, {account}) => {
    state = Object.assign(state, {account});
  },
  [INVITE_USER_SUCCESS]: (state) => {
    appNotification.notify({
      title: 'Invite sent!',
      message: 'Ask your team member to check their email',
    });
  },
  [REINVITE_USER_ACTIVE]: (state) => {
    appNotification.notify({
      title: 'Reinvite member!',
      message: 'Member account is activated',
    });
  },
  [USER_ANALYTICS_SUCCESS]: (state, {textAnalytics}) => {
    state = Object.assign(state, {textAnalytics});
  },
  [ACCOUNT_ANALYTICS_SUCCESS]: (state, {accountTextAnalytics}) => {
    state = Object.assign(state, {accountTextAnalytics});
  },
  [BUY_PHONE_NUMBER_SUCCESS]: (state, {isBuyPhoneNumber}) => {
    state = Object.assign(state, {isBuyPhoneNumber});
  },
  [SET_MFA_NEEDED](state, payload) {
    state.mfaNeeded = payload; // Set the MFA flag in state
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
