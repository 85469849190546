<template>
  <div>
    <div class="wrapper wrapper-full-page">
      <div class="full-page">
        <div class="content">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-7 col-md-7 col-sm-7 d-flex flex-column align-items-center">
                <div class="logo-container">
                  <logo customStyle="max-width: 280px;"/>
                </div>
                <router-view></router-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Logo from '../../components/App/UI/Logo.vue';

export default {
  components: {Logo},
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      document.body.classList.remove('off-canvas-sidebar');
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style lang="scss" scoped>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.navbar-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.wrapper-full-page .navbar-toggler,
.wrapper-full-page .navbar-collapse .navbar-nav {
  margin-right: 30px;
}

.navbar-collapse .navbar-nav .nav-link {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper-full-page {
  background: #F8F9FE;
}

h1 {
  font-weight: 700;
}

form {
  margin: 40px 0;
}

.logo-container {
  margin: 40px 0;

  svg {
    width: 175px;
  }
}

.full-page {
  background-image: url('/img/brand/main_bg.png');
  background-size: contain; /* or 'contain', depending on your preference */
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh; /* adjust as necessary */
}
</style>
