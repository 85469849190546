import axios from 'axios';
import appNotification, {notificationsTypes} from '../util/appNotification';
import store from '@/store';
import {LOGOUT_REQUEST} from '../store/storeActions';
import {getCookie} from '../util/appLocalStorage';

const UNAUTHORITHED_MESSAGES = [
  'No authorization token was found',
];

const messagesDictionary = [
  {
    be: '"callForwardingNumber" is not allowed to be empty',
    fe: 'Please enter a phone number for call forwarding',
  },
];

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

api.interceptors.request.use(
  (config) => {
    const idToken = getCookie('idToken');
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const {status, data} = error.response;
    const message = (!!data.message) ? apiMessagesMapper(data.message) : 'Server error';
    if (status === 401 || UNAUTHORITHED_MESSAGES.some((msg) => msg === message)) {
      store.dispatch(LOGOUT_REQUEST, {onServer: false});
    }
    if (!!message) {
      appNotification.notify({message, type: notificationsTypes.danger});
    }
    return Promise.reject(error);
  },
);

export default api;

function apiMessagesMapper(message) {
  let resMessage = message;
  const msgFromDictionary = messagesDictionary.find((msg) => msg.be === message);
  if (!!msgFromDictionary) {
    resMessage = msgFromDictionary.fe;
  }
  return resMessage;
}
