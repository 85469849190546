import api from './axios';

export const loginApi = ({email, password}) => {
  return api.post('/auth/login', {email, password});
};

export const signupApi = (payload) => {
  return api.post('/auth/signup', payload);
};

export const resetPasswordWithEmailApi = (payload) => {
  return api.post('/auth/sendResetPasswordEmail', payload);
};

export const resetPasswordUsingCodeApi = (payload) => {
  return api.post('/auth/resetPasswordUsingCode', payload);
};

export const logoutApi = () => {
  return api.post('/auth/logout');
};

export const sendVerificationEmailApi = ({email, captchaToken}) => {
  return api.post('/auth/sendVerificationEmail', {email, captchaToken});
};

export const verifyMfaApi = ({email, code}) => {
  return api.post('/auth/verifyMfa', {email, code});
};

export const sendMfaEmailApi = ({email, captchaToken}) => {
  return api.post('/auth/sendMfaEmail', {email, captchaToken});
};

export const getSsoUrlApi = (email) => {
  return api.get(`/auth/getSsoUrl`, { params: { email } });
};