// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills';
// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin';
// Validation plugin used to validate forms
import {configure, extend} from 'vee-validate';
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents';
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives';
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '@/components/SidebarPlugin';

// element ui language configuration
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
// vue-bootstrap
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';

// asset imports
import '@/assets/sass/argon.scss';
import '@/assets/css/nucleo/css/nucleo.css';
import * as rules from 'vee-validate/dist/rules';
import {messages} from 'vee-validate/dist/locale/en.json';
import {cardExpiredRule, creditCardRule, fullNameRule, isBusyRule, phoneNumberRule} from './customValidationRules';

locale.use(lang);

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  });
});

extend('url', {
  validate: (value) => {
    const urlRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:((?:(?!-)[A-Z\d-]{1,63}(?<!-)\.)+(?:[A-Z]{2,}))|localhost)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
    return urlRegex.test(value);
  },
  message: 'The {_field_} field must be a valid URL.',
});

extend('phoneNumber', phoneNumberRule);
extend('creditCard', creditCardRule);
extend('cardExpired', cardExpiredRule);
extend('fullNameRule', fullNameRule);
extend('isBusy', isBusyRule);

extend('customRequired', {
  ...rules['required'],
  message: (field, params) => {
    const {allowFalse: customMsg} = params;
    return customMsg === true ? `The ${field} is required` : customMsg;
  },
});

extend('customEmail', {
  ...rules['email'],
  message: (field, params) => {
    const {multiple: customMsg} = params;
    return customMsg || `The ${field} is invalid`;
  },
});

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    configure({
      classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
      },
    });
  },
};
