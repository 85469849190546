import api from './axios';

export const getUserApi = () => {
  return api.get('/user');
};

export const updateUserApi = (data) => {
  return api.put('/user', data);
};

export const updateCallForwardSettingsApi = (data) => {
  return api.post('/user/updateCallForwardSettings', data);
};

export const deleteUserApi = () => {
  // test method will change later
  return api.delete('/user');
};

export const getUserAnalyticsApi = (date) => {
  return api.get(`/user/textAnalytics?date=${date}`);
};

export const changeUserType = (payload) => {
  return api.put('/user/changeUserType', payload);
};

export const getOutOfOffice = () => {
  return api.get(`/user/getOutOfOffice`);
};

export const updateOutOfOffice = (payload) => {
  return api.put('/user/updateOutOfOffice', payload);
};

export const getOutOfOfficeByUserId = (userId) => {
  return api.get(`/user/getUserOfficeByUserId/${userId}`);
};

export const updateOutOfOfficeByUserId = (userId, payload) => {
  return api.put(`/user/updateOutOfOfficeByUserId/${userId}`, payload);
};
