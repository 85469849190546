<template>
  <card class="p-3">
    <h1 class="page-header mb-lg-3 card-title">404</h1>
    <p>Oops! The page you're looking for doesn't exist.</p>
    <button class="btn btn-outline btn-primary btn-block font-weight-bold" @click="$router.push({name: 'Home'})">Go
      Home
    </button>
  </card>
</template>

<script>

export default {
  name: 'not-found',
};
</script>
