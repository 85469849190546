<template>
  <div><img alt="rectxt logo" class="logo" :style="customStyle" src="../../../../public/img/brand/new_logo.png"></div>
</template>
<script>
export default {
  name: 'logo',
  props: {
    customStyle: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  max-width: 172px;
}
</style>

