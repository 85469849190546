import api from './axios';

export const getTemplatesApi = () => {
  return api.get('/templates');
};

export const getTemplateAttributesApi = () => {
  return api.get('/templates/attributes');
};


export const createTemplateApi = (payload) => {
  return api.post('/templates', payload);
};

export const updateTemplateApi = ({payload, id}) => {
  return api.put(`/templates/${id}`, payload);
};

export const deleteTemplateApi = (id) => {
  return api.delete(`/templates/${id}`);
};

// Team templates API
export const getTeamTemplatesApi = () => {
  return api.get('/teamTemplates');
};

export const createTeamTemplateApi = (payload) => {
  return api.post('/teamTemplates', payload);
};

export const updateTeamTemplateApi = ({payload, id}) => {
  return api.put(`/teamTemplates/${id}`, payload);
};

export const deleteTeamTemplateApi = (id) => {
  return api.delete(`/teamTemplates/${id}`);
};

export const getHmTemplatesApi = () => {
  return api.get('/templates?templateType=hiringManagerTemplate');
};
