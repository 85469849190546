<template>
  <div v-if="getLoaderState"
       class="loader-wrapper d-flex position-fixed vh-100 vw-100 justify-content-center align-items-center">
    <div class="d-flex flex-column align-items-center">
      <div class="spinner-grow" role="status" style="width: 3rem; height: 3rem;">
        <span class="sr-only"></span>
      </div>
      <span class="loader-title">{{ getLoaderText }}...</span>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'getLoaderState',
      'getLoaderText',
    ]),
  },
};
</script>
<style scoped>
.loader-wrapper {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  z-index: 1000;
  background: #4a4a4a6b;
  color: #fff;
  top: 0;
  left: 0;
}

.loader .loader-title {
  margin-top: 10px;
}
</style>
