import {atsIntegrationTestConnectionApi, deleteAtsIntegrationApi, getAtsIntegrationApi, updateAtsIntegrationApi} from '@/api/atsIntegrationApi';
import appNotification, {notificationsTypes} from '../util/appNotification';
import {oauthIntegrationWindow} from '@/views/Dashboard/integrations/integrationsUtil';
import {
  ATS_INTEGRATION_CONNECTION_REQUEST,
  ATS_INTEGRATION_OAUTH_REQUEST,
  CLEAR_ATS_INTEGRATIONS,
  DELETE_ATS_INTEGRATION_REQUEST,
  FETCH_ATS_INTEGRATION_REQUEST,
  FETCH_ATS_INTEGRATION_SUCCESS,
  LOADING_STATE_SET,
  UPDATE_ATS_INTEGRATION_REQUEST,
} from './storeActions';

const getInitialState = () => {
  return {
    atsIntegration: null,
    integrationLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  atsIntegration: (state) => state.atsIntegration,
  atsIntegrationEnabled: (state) => state.atsIntegration ?
    state.atsIntegration.atsIntegrationEnabled :
    false,
  integrationLoaded: (state) => state.integrationLoaded,
  atsIntegrationAccountId: (state) => state.atsIntegration &&
    state.atsIntegration.atsAccountId,
};

const actions = {
  [FETCH_ATS_INTEGRATION_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading'});
    try {
      const atsIntegration = await getAtsIntegrationApi();
      if (atsIntegration) {
        commit(FETCH_ATS_INTEGRATION_SUCCESS, {atsIntegration});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [ATS_INTEGRATION_CONNECTION_REQUEST]: async (
    {commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Connecting'});
    try {
      const res = await atsIntegrationTestConnectionApi(payload);
      if (res && res.status === 'ok') {
        dispatch(UPDATE_ATS_INTEGRATION_REQUEST, {payload});
      }
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [ATS_INTEGRATION_OAUTH_REQUEST]: async ({dispatch}, {type}) => {
    try {
      const res = await oauthIntegrationWindow({type});
      if (res) {
        dispatch(FETCH_ATS_INTEGRATION_REQUEST);
        appNotification.notify({title: 'ATS successfully connected'});
      } else {
        appNotification.notify({
          title: 'ATS integration failed. Please try again.',
          type: notificationsTypes.danger,
        });
      }
    } catch (e) {
    }
  },
  [UPDATE_ATS_INTEGRATION_REQUEST]: async ({commit}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating'});
    try {
      const atsIntegration = await updateAtsIntegrationApi(payload);
      if (atsIntegration) {
        commit(FETCH_ATS_INTEGRATION_SUCCESS, {atsIntegration});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_ATS_INTEGRATION_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Disconnection'});
    try {
      const res = await deleteAtsIntegrationApi();
      res && commit(CLEAR_ATS_INTEGRATIONS);
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_ATS_INTEGRATION_SUCCESS]: (state, {atsIntegration}) => {
    state = Object.assign(state, {atsIntegration, integrationLoaded: true});
  },
  [CLEAR_ATS_INTEGRATIONS]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
