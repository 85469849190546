import store from '/src/store';
import {FETCH_AUTH_DATA_REQUEST, LOGOUT_REQUEST} from '../store/storeActions';
import {dashboardRoutes} from './routes';
import router from './router';
import {accountTypes, plansTypes, seatTypes, userTypes} from '../store/userConstants';
import {getCookie} from '../util/appLocalStorage';

export async function redirectUnauthenticated(to, from, next) {
  let allow = true;
  if (dashboardRoutes.some((page) => page.name === to.name)) {
    let userId = store.getters.userId;
    if (!userId) {
      const res = await store.dispatch(FETCH_AUTH_DATA_REQUEST);
      if (!!res && !!res.user) {
        userId = res.user.id;
      }
    }
    allow = !!userId;
  }
  allow ? next() : store.dispatch(LOGOUT_REQUEST, {onServer: false});
}

export async function dashboardRolesGuard(to, from, next) {
  let allow = true;
  const route = dashboardRoutes.find((r) => r.name === to.name);
  if (route) {
    let {userType, accountType, planType, seatType, accountName, userEmail, seatRole} = store.getters;
    if (!userType || !accountType || !planType || !seatType || !accountName) {
      const res = await store.dispatch(FETCH_AUTH_DATA_REQUEST);
      if (res && res.user && res.account) {
        userType = res.user.userType;
        accountType = res.account.type;
        planType = res.account.planType;
        seatType = res.user.seat && res.user.seat.id ? seatTypes.TEXTER : seatTypes.NON_TEXTER;
        accountName = res.account.name;
        userEmail = res.user.email;
        seatRole = res.user.seat.seatRole;
      }
    }
    allow = isDashboardRouteAllow(route.name, userType, accountType, planType, seatType, accountName, userEmail,
      seatRole);
  }
  allow ? next() : router.push({name: 'Login'}).catch((err) => err);
}

export function isDashboardRouteAllow(
  routeName, userType, accountType, planType, seatType, accountName, userEmail, seatRole) {
  let allow = true;
  const route = dashboardRoutes.find((r) => r.name === routeName);
  if (route) {
    if (route.usersTypes) {
      allow = route.usersTypes.some((uType) => uType === userType);
    }
    if (route.accountsTypes && allow) {
      allow = route.accountsTypes.some((aType) => aType === accountType);
    }
    if (route.plansTypes && allow) {
      allow = route.plansTypes.some((pType) => pType === planType);
    }
    if (route.seatTypes && allow) {
      allow = !route.seatTypes.some((iType) => iType === seatTypes);
    }
    if (route.isMemberAllow && allow) {
      const isallow = userType === userTypes.MEMBER || planType === plansTypes.TEAM;
      allow = !(isallow &&
        route.isMemberAllow.some((iType) => iType === accountName));
    }
    if (route.isRectxtUser && allow) {
      allow = userEmail.includes('@rectxt.com') || userEmail.includes('@keeyora.com'); // Only @rectxt.com user allow
    }
    if (route.seatRoles && allow) {
      if (userType === userTypes.ADMIN) return true;
      allow = route.seatRoles.some((sRole) => sRole === seatRole);
    }
  }
  return allow;
}

export function redirectAuthenticatedToDashboard(to, from, next) {
  let redirect = false;

  if (to.name === 'Login') {
    const idToken = getCookie('idToken');
    redirect = !!idToken;
  }
  redirect ? router.push({name: 'Account'}).catch((err) => err) : next();
}

export async function PlanSelectionPageGuard(to, from, next) {
  let redirect = false;
  if (to.name === 'Plan Selection') {
    const {userType, accountType, planType} = store.getters;
    if (userType === userTypes.MEMBER && accountType === accountTypes.TEAM &&
      [plansTypes.ANNUAL, plansTypes.MONTHLY].indexOf(planType) !== -1) {
      redirect = true;
    }
  }
  redirect ? router.push({name: 'Account'}).catch((err) => err) : next();
}

export function isTemplateAndTeamTemplatesRouteAllow(
  routeName, userType, accountType, planType, twilioNumberFormatted) {
  let allow = false;
  if (routeName && routeName === 'Templates') {
    // Unlimited user or team user (non admin)
    if (((accountType === accountTypes.PERSONAL) ||
        (accountType === accountTypes.TEAM && userType === userTypes.MEMBER)) &&
      [plansTypes.ANNUAL, plansTypes.MONTHLY].indexOf(planType) !== -1) {
      allow = true;
    }

    // Team admin and texter
    if ((accountType === accountTypes.TEAM &&
        userType === userTypes.ADMIN && twilioNumberFormatted) &&
      [plansTypes.ANNUAL, plansTypes.MONTHLY].indexOf(planType) !== -1) {
      allow = true;
    }
  }

  if (routeName && routeName === 'Team Templates') {
    // Team admin and (texter or non texter)
    if ((accountType === accountTypes.TEAM && userType === userTypes.ADMIN) &&
      [plansTypes.ANNUAL, plansTypes.MONTHLY].indexOf(planType) !== -1) {
      allow = true;
    }
  }

  return allow;
}
