import DashboardRouter from '@/views/Layout/DashboardRouter.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import CommonLayout from '@/views/Layout/CommonLayout.vue';

// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
import {accountTypes, analyticsHide, plansTypes, seatRoles, seatTypes, userTypes} from '@/store/userConstants';
import {PlanSelectionPageGuard} from './guards';

// Dashboard pages
const Account = () => import('@/views/Dashboard/account/Account.vue');
const Team = () => import('@/views/Dashboard/team/Team.vue');
const TeamHiringManagers = () => import('@/views/Dashboard/team/TeamHiringManagers.vue');
const Templates = () => import('@/views/Dashboard/templates/Templates.vue');
const TeamTemplates = () => import('@/views/Dashboard/templates/TeamTemplates.vue');
const HiringManagerTemplates = () => import('@/views/Dashboard/templates/HiringManagerTemplates.vue');
const Integrations = () => import('@/views/Dashboard/integrations/Integrations.vue');
const Billing = () => import('@/views/Dashboard/billing/Billing.vue');
const GetNumberPage = () => import('@/views/Dashboard/GetNumberPage.vue');
const PlanSelectionPage = () => import('@/views/Dashboard/PlanSelectionPage.vue');
const SelectPlanPage = () => import('@/views/Dashboard/SelectPlanPage.vue');
const Analytics = () => import('@/views/Dashboard/analytics/Analytics.vue');
const Automations = () => import('@/views/Dashboard/automations/Automations.vue');
const Calendars = () => import('@/views/Dashboard/calendars/Calendar.vue');

// Pages
const Login = () => import('@/views/Pages/Login.vue');
const ResetPassword = () => import('@/views/Pages/ResetPassword.vue');
const Register = () => import( '@/views/Pages/Register.vue');
const OAuthSuccess = () => import('@/views/Pages/OAuthSuccess.vue');
const OAuthFailure = () => import('@/views/Pages/OAuthFailure.vue');
const Callback = () => import('@/views/Pages/Callback.vue');
const TwoFactorAuthentication = () => import('@/views/Pages/TwoFactorAuthentication.vue');
const SingleSignOn = () => import('@/views/Pages/SingleSignOn.vue');
const EmailVerificationSuccess = () => import('@/views/Pages/EmailVerificationSuccess.vue');

export const authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/register',
      name: 'Register',
      component: Register,
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
    },
    {
      path: '/oauthSuccess',
      component: OAuthSuccess,
    },
    {
      path: '/oauthFailure',
      component: OAuthFailure,
    },
    {
      path: '/callback',
      component: Callback,
    },
    {
      path: '/two-factor-authentication',
      name: 'TwoFactorAuthentication',
      component: TwoFactorAuthentication,
    },
    {
      path: '/single-sign-on',
      name: 'SingleSignOn',
      component: SingleSignOn,
    },
    {
      path: '/emailVerificationSuccess',
      component: EmailVerificationSuccess,
    },
  ],
};

export const commonPages = {
  path: '/',
  component: CommonLayout,
  name: 'Common',
  children: [
    {path: '*', component: NotFound},
  ],
};

export const dashboardRoutes = [
  {
    name: 'Dashboard layout',
    path: '/',
    redirect: 'account',
  },
  {
    path: 'account',
    name: 'Account',
    component: Account,
  },
  {
    path: 'team',
    name: 'Team',
    component: Team,
    usersTypes: [userTypes.ADMIN],
    seatRoles: [seatRoles.RECRUITER],
    accountsTypes: [accountTypes.TEAM],
  },
  {
    path: 'hiring-managers',
    name: 'Hiring Managers',
    component: TeamHiringManagers,
    usersTypes: [userTypes.ADMIN],
    seatRoles: [seatRoles.RECRUITER],
    accountsTypes: [accountTypes.TEAM],
  },
  {
    path: 'analytics',
    name: 'Analytics',
    component: Analytics,
    accountsTypes: [accountTypes.TEAM],
    seatRoles: [seatRoles.RECRUITER],
    isMemberAllow: analyticsHide,
  },
  {
    path: 'templates',
    name: 'Templates',
    component: Templates,
    plansTypes: [plansTypes.MONTHLY, plansTypes.ANNUAL],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'team-templates',
    name: 'Team Templates',
    component: TeamTemplates,
    plansTypes: [plansTypes.MONTHLY, plansTypes.ANNUAL],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'hiring-manager-templates',
    name: 'Hiring Manager Templates',
    component: HiringManagerTemplates,
    plansTypes: [plansTypes.MONTHLY, plansTypes.ANNUAL],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'integrations',
    name: 'Integrations',
    component: Integrations,
    usersTypes: [userTypes.ADMIN],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'billing',
    name: 'Billing',
    component: Billing,
    usersTypes: [userTypes.ADMIN],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'automations',
    name: 'Automations',
    component: Automations,
    plansTypes: [plansTypes.MONTHLY, plansTypes.ANNUAL],
    usersTypes: [userTypes.ADMIN, userTypes.MEMBER],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'calendars',
    name: 'Calendars',
    component: Calendars,
    plansTypes: [plansTypes.MONTHLY, plansTypes.ANNUAL],
    seatRoles: [seatRoles.RECRUITER],
  },
  {
    path: 'get-number',
    name: 'Get Number',
    component: GetNumberPage,
    seatTypes: [seatTypes.NON_TEXTER],
  },
  {
    path: 'plan-selection',
    name: 'Plan Selection',
    component: PlanSelectionPage,
    beforeEnter: PlanSelectionPageGuard,
  },
  {
    path: 'select-plan',
    name: 'Select Plan',
    component: SelectPlanPage,
  },
];

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Home',
  },
  {
    path: '/dashboard',
    component: DashboardRouter,
    children: dashboardRoutes,
  },
  authPages,
  commonPages,
];

export default routes;
