import {getOutOfOffice, getOutOfOfficeByUserId, updateOutOfOffice, updateOutOfOfficeByUserId} from '@/api/userApi';

import {
  CLEAR_USER_OFFICE_MSG,
  FETCH_OUT_OF_OFFICE_MSG_REQUEST,
  FETCH_SEATS_REQUEST,
  FETCH_USER_OFFICE_MSG_REQUEST,
  FETCH_USER_OFFICE_MSG_SUCCESS,
  FETCH_USER_REQUEST,
  LOADING_STATE_SET,
  UPDATE_OUT_OF_OFFICE_MSG_REQUEST,
  UPDATE_USER_OFFICE_MSG_REQUEST,
} from './storeActions';

const getInitialState = () => {
  return {
    outOfOfficeLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  outOfOfficeLoaded: (state) => state.outOfOfficeLoaded,
};

const actions = {
  [FETCH_USER_OFFICE_MSG_REQUEST]: async ({commit}, {userId}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading out of office templates'});
    try {
      const outOfOffice = await getOutOfOfficeByUserId(userId);
      if (!!outOfOffice) {
        commit(FETCH_USER_OFFICE_MSG_SUCCESS, outOfOffice);
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_USER_OFFICE_MSG_REQUEST]: async ({commit, dispatch}, {userId, payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating office template'});
    try {
      const template = await updateOutOfOfficeByUserId(userId, payload);
      if (!!template) {
        dispatch(FETCH_USER_REQUEST);
        try {
          const outOfOffice = await getOutOfOfficeByUserId(userId);
          if (!!outOfOffice) {
            commit(FETCH_USER_OFFICE_MSG_SUCCESS, outOfOffice);
          }
        } catch (e) { } finally {
          commit(LOADING_STATE_SET, {isLoading: false});
        }
        dispatch(FETCH_SEATS_REQUEST);
      }
      if (payload.team) {
        dispatch(FETCH_SEATS_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_OUT_OF_OFFICE_MSG_REQUEST]: async ({commit}, {}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading out of office templates'});
    try {
      const outOfOffice = await getOutOfOffice();
      if (!!outOfOffice) {
        commit(FETCH_USER_OFFICE_MSG_SUCCESS, outOfOffice);
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_OUT_OF_OFFICE_MSG_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating office template'});
    try {
      const template = await updateOutOfOffice(payload);
      if (!!template) {
        dispatch(FETCH_USER_REQUEST);
        try {
          const outOfOffice = await getOutOfOffice();
          if (!!outOfOffice) {
            commit(FETCH_USER_OFFICE_MSG_SUCCESS, outOfOffice);
          }
        } catch (e) { } finally {
          commit(LOADING_STATE_SET, {isLoading: false});
        }
      }
      if (payload.team) {
        dispatch(FETCH_SEATS_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_USER_OFFICE_MSG_SUCCESS]: (state, {outOfOfficeMessageEnabled, outOfOfficeMessage}) => {
    state = Object.assign(state, {outOfOfficeMessageEnabled, outOfOfficeMessage, outOfOfficeLoaded: true});
  },
  [CLEAR_USER_OFFICE_MSG]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
