import Vue from 'vue';
import moment from 'moment';
import VueIntercom from '@mathieustan/vue-intercom';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import Vuetify from 'vuetify';
// router setup
import router from './routes/router';
import store from './store';
// filters setup
import './util/fliter';

Vue.use(VueIntercom, {appId: process.env.VUE_APP_INTERCOM_ID});
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Vuetify);

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('lll');
  }
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
  vuetify: Vuetify,
});
