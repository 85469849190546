import {getAccountSeatsApi} from '../api/accountApi';
import {cancelPlanApi, getNewNumberApi, transferHiringManagerSeatApi, updateSeatTagsApi} from '../api/seatApi';
import {
  CANCEL_PLAN_REQUEST,
  CLEAR_SEATS_DATA,
  FETCH_AUTH_DATA_REQUEST,
  FETCH_SEATS_REQUEST,
  FETCH_SEATS_SUCCESS,
  GET_NEW_NUMBER,
  LOADING_STATE_SET,
  TRANSFER_HIRING_MANAGER_SEAT,
  UPDATE_SEAT_TAGS,
} from './storeActions';
import appNotification from '@/util/appNotification';

const getInitialState = () => {
  return {
    seats: [],
    seatsLoaded: false,
    freeUsersLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  accountSeats: (state) => state.seats,
  accountSeatsLoaded: (state) => state.seatsLoaded,
  accountFreeUsersLoaded: (state) => state.freeUsersLoaded,
};

const actions = {
  [FETCH_SEATS_REQUEST]: async ({commit}, payload = {fromFlag: 'team'}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading seats'});
    try {
      const seats = await getAccountSeatsApi();
      if (Array.isArray(seats)) {
        commit(FETCH_SEATS_SUCCESS, {seats, payload});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CANCEL_PLAN_REQUEST]: async ({commit, dispatch}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Cancelling'});
    try {
      await cancelPlanApi();
      dispatch(FETCH_AUTH_DATA_REQUEST);
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_SEAT_TAGS]: async ({commit, dispatch}, {seatId, payload}) => {
    const loaderText = 'Updating tags';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const res = await updateSeatTagsApi(seatId, payload);
      if (res) {
        dispatch(FETCH_SEATS_REQUEST);
        const message = 'Tags updated successfully!';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [TRANSFER_HIRING_MANAGER_SEAT]: async ({commit, dispatch}, {seatId, payload}) => {
    const loaderText = 'Transferring Seat';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const result = await transferHiringManagerSeatApi(seatId, payload);
      if (result) {
        dispatch(FETCH_SEATS_REQUEST);
        const message = 'Seat transferred successfully!';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [GET_NEW_NUMBER]: async ({commit, dispatch}, {seatId, payload}) => {
    const loaderText = 'Getting new number';
    commit(LOADING_STATE_SET, {isLoading: true, loaderText});
    try {
      const result = await getNewNumberApi(seatId, payload);
      if (result) {
        dispatch(FETCH_SEATS_REQUEST);
        const message = 'Get new number successfully!';
        appNotification.notify({message});
      }
    } catch (e) {
    } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_SEATS_SUCCESS]: (state, {seats, payload}) => {
    if (payload.fromFlag === 'team') {
      state = Object.assign(state, {seats, accountFreeUsersLoaded: true});
    } else {
      state = Object.assign(state, {seats, seatsLoaded: true});
    }
  },
  [CLEAR_SEATS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
