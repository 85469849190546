import {createCalendarApi, deleteTemplateApi, getCalendarsApi, updateTemplateApi, updateUserCalendar} from '@/api/calendarShortcutApi';

import {
  CLEAR_CALENDARS_DATA,
  CREATE_USER_CALENDAR_REQUEST,
  DELETE_CALENDAR_TEMPLATE_REQUEST,
  FETCH_CALENDARS_SUCCESS,
  FETCH_USER_CALENDARS_REQUEST,
  LOADING_STATE_SET,
  UPDATE_TEMPLATE_CALENDAR_REQUEST,
  UPDATE_USER_CALENDAR_REQUEST,
} from './storeActions';
import appNotification from '../util/appNotification';

const getInitialState = () => {
  return {
    calendarShortcuts: [],
    calendarLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  calendarShortcuts: (state) => state.calendarShortcuts,
  calendarLoaded: (state) => state.calendarLoaded,
};

const actions = {
  [UPDATE_USER_CALENDAR_REQUEST]: async ({commit}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: false, loaderText: 'Updating user calendar'});
    try {
      const result = await updateUserCalendar({payload});
      return result;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_USER_CALENDARS_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading calendar templates'});
    try {
      const calendarShortcuts = await getCalendarsApi();
      if (!!calendarShortcuts) {
        commit(FETCH_CALENDARS_SUCCESS, {calendarShortcuts});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CREATE_USER_CALENDAR_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Creating calendar template'});
    try {
      const template = await createCalendarApi({payload});
      if (!!template) {
        dispatch(FETCH_USER_CALENDARS_REQUEST);
        const message = 'Calendar created successfully!';
        appNotification.notify({message});
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_TEMPLATE_CALENDAR_REQUEST]: async ({commit, dispatch}, {payload, id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating calendar template'});
    try {
      const template = await updateTemplateApi({payload, id});
      if (!!template) {
        dispatch(FETCH_USER_CALENDARS_REQUEST);
        const message = 'Calendar updated successfully!';
        appNotification.notify({message});
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_CALENDAR_TEMPLATE_REQUEST]: async ({commit, dispatch}, {id}) => {
    commit(LOADING_STATE_SET, {isLoading: false, loaderText: 'Deleteing user calendar template'});
    try {
      const result = await deleteTemplateApi(id);
      if (!!result) {
        dispatch(FETCH_USER_CALENDARS_REQUEST);
      }
      return result;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [CLEAR_CALENDARS_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
  [FETCH_CALENDARS_SUCCESS]: (state, {calendarShortcuts}) => {
    state = Object.assign(state, {calendarShortcuts, calendarLoaded: true});
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
