import {createTemplateApi, deleteTemplateApi, getTemplateAttributesApi, getTemplatesApi, updateTemplateApi} from '@/api/templatesApi';

import {
  CLEAR_TEMPLATES_DATA,
  CREATE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST,
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATE_ATTRIBUTES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  LOADING_STATE_SET,
  UPDATE_TEMPLATE_REQUEST, FETCH_TEMPLATE_ATTRIBUTES_SUCCESS,
} from './storeActions';

const getInitialState = () => {
  return {
    templates: [],
    templateAttributes: [],
    templatesLoaded: false,
  };
};

const state = getInitialState();

const getters = {
  templates: (state) => state.templates,
  templateAttributes: (state) => state.templateAttributes,
  templatesLoaded: (state) => state.templatesLoaded,
};

const actions = {
  [FETCH_TEMPLATES_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading templates'});
    try {
      const templates = await getTemplatesApi();
      if (!!templates) {
        commit(FETCH_TEMPLATES_SUCCESS, {templates});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [FETCH_TEMPLATE_ATTRIBUTES_REQUEST]: async ({commit}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Loading template attributes'});
    try {
      const data = await getTemplateAttributesApi();
      if (!!data) {
        const templateAttributes = Object.keys(data);
        commit(FETCH_TEMPLATE_ATTRIBUTES_SUCCESS, {templateAttributes});
      }
    } catch (e) { } finally {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [CREATE_TEMPLATE_REQUEST]: async ({commit, dispatch}, {payload}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Creating template'});
    try {
      const template = await createTemplateApi(payload);
      if (!!template) {
        dispatch(FETCH_TEMPLATES_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [UPDATE_TEMPLATE_REQUEST]: async ({commit, dispatch}, {payload, id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Updating template'});
    try {
      const template = await updateTemplateApi({payload, id});
      if (!!template) {
        dispatch(FETCH_TEMPLATES_REQUEST);
      }
      return template;
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
  [DELETE_TEMPLATE_REQUEST]: async ({commit, dispatch}, {id}) => {
    commit(LOADING_STATE_SET, {isLoading: true, loaderText: 'Deleting template'});
    try {
      const res = await deleteTemplateApi(id);
      if (!!res) {
        dispatch(FETCH_TEMPLATES_REQUEST);
      }
    } catch (e) {
      commit(LOADING_STATE_SET, {isLoading: false});
    }
  },
};

const mutations = {
  [FETCH_TEMPLATES_SUCCESS]: (state, {templates}) => {
    state = Object.assign(state, {templates, templatesLoaded: true});
  },
  [FETCH_TEMPLATE_ATTRIBUTES_SUCCESS]: (state, {templateAttributes}) => {
    state = Object.assign(state, {templateAttributes});
  },
  [CLEAR_TEMPLATES_DATA]: (state) => {
    state = Object.assign(state, getInitialState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
